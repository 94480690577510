<template>
  <section class="section__container">
    <div class="section__container_background">
      <v-img
        :cover="true"
        max-width="100vw"
        max-height="100%"
        src="/backgrounds/page_quemsomos_regional.png"
        alt=""
      />
      <GoBack />
    </div>
    <div class="container position--relative">
      <v-row class="container-fluid">
        <Breadcrumb class="sobre_section_bc-color">
          <template v-slot:breadcrumbLinks>
            <router-link
              :style="{
                color: color,
              }"
              tag="p"
              :to="{ name: link.href }"
              v-for="(link, idx) in breadcrumbItems"
              :key="idx"
            >
              {{ link.text }}
            </router-link>
          </template>
        </Breadcrumb>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col sm="12" md="7">
          <Card
            title="Cáritas Regional Minas Gerais e a inserção em Mariana"
            borderColor="#C92F05"
            titleColor="#C92F05"
            title_alignment="center"
            backgroundColor="#F9F7ED"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                A Cáritas Brasileira Regional Minas Gerais foi fundada em 08 de
                março de 1989 e é uma das regionais integrantes da Cáritas
                Brasileira. A entidade se dedica ao Desenvolvimento Solidário
                Sustentável e Territorial, na perspectiva de um projeto popular
                de sociedade democrática.
              </p>
              <p>
                Em Minas Gerais, a Cáritas Regional é composta por 16 entidades
                membros e possui atuação em diferentes regiões do estado: Região
                Central, Norte, Noroeste, Vale do Jequitinhonha, Vale do Rio
                Doce, Triângulo Mineiro, Zona da Mata e Região Metropolitana de
                Belo Horizonte. Assim, a Cáritas Regional Minas Gerais tem
                optado por dez diferentes áreas de atuação que refletem a
                pluralidade de práticas que a Cáritas Brasileira já empreende no
                país e em toda a Rede. Veja abaixo as
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="http://mg.caritas.org.br/area-de-atuacao"
                  class="pink--text"
                  >dez áreas de atuação</a
                >
                da Cáritas no território brasileiro:
              </p>
            </template>
          </Card>

          <Card
            borderColor="#C92F05"
            backgroundColor="#F9F7ED"
            class="mt-5 mb-5"
          >
            <template v-slot:conteudo>
              <v-row>
                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="1. Economia Popular Solidária"
                  >
                    <template v-slot:img>
                      <img
                        src="../../assets/economia_popular_solidaria.png"
                        alt=""
                      />
                    </template>
                  </InsideCard>
                </v-col>
                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="2. Convivência com os biomas"
                  >
                    <template v-slot:img>
                      <img src="../../assets/convivencia_biomas.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>

                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="3. Migração, Refúgio e Apátrida"
                  >
                    <template v-slot:img>
                      <img
                        src="../../assets/migracao_refugio_apatrida.png"
                        alt=""
                      />
                    </template>
                  </InsideCard>
                </v-col>
                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="4. Segurança Alimentar e Nutricional"
                  >
                    <template v-slot:img>
                      <img src="../../assets/seguranca_alimentar.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>

                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="5. Programa Infância, Adolescência e Juventudes"
                  >
                    <template v-slot:img>
                      <img src="../../assets/programa_infancia.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>
                <v-col cols="12" sm="6">
                  <InsideCard class="inside__card" subtitle="6. Mundo Urbano">
                    <template v-slot:img>
                      <img src="../../assets/mundo_urbano.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>

                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="7. Mulheres e equidade de gênero"
                  >
                    <template v-slot:img>
                      <img src="../../assets/equidade_genero.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>
                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="8. Gestão de Resíduos Sólidos com Ênfase em Catadores"
                  >
                    <template v-slot:img>
                      <img src="../../assets/gestao_residuos.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>

                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="9. Povos e Comunidades Tradicionais"
                  >
                    <template v-slot:img>
                      <img src="../../assets/povos_tradicionais.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>
                <v-col cols="12" sm="6">
                  <InsideCard
                    class="inside__card"
                    subtitle="10. Meio Ambiente e Gestão de Riscos e Emergências (MAGRE)"
                  >
                    <template v-slot:img>
                      <img src="../../assets/magre.png" alt="" />
                    </template>
                  </InsideCard>
                </v-col>
              </v-row>
            </template>
          </Card>

          <Card borderColor="#C92F05" backgroundColor="#F9F7ED" class="mt-5">
            <template v-slot:conteudo>
              <p>
                É através do MAGRE que a Cáritas Regional Minas Gerais atua em
                várias situações de desastres causadas por enchentes, como a de
                1979 em Araçuaí-MG, e/ou as causadas por rompimentos de
                barragens, como a de Fundão em 2015, em Mariana. Neste caso,
                quando ocorreu o rompimento da barragem de Fundão, um grupo da
                Cáritas que opera na linha de atuação do MAGRE foi enviado a
                Mariana para acompanhar o diálogo com a Arquidiocese e trabalhar
                no primeiro momento a fim de prestar assistência emergencial.
                Assim, junto à Arquidiocese de Mariana, a Cáritas fez uma
                campanha de arrecadação de donativos que somaram R$1 milhão.
              </p>
              <p>
                A partir desta campanha de solidariedade, o Ministério Público
                de Minas Gerais, por meio da 2ª Promotoria de Justiça de
                Mariana, juntamente à Comissão de Atingidos e à Arquidiocese de
                Mariana, celebraram um Termo de Compromisso Preliminar<sup
                  >1</sup
                >
                no dia 18 de julho de 2016, no intuito de viabilizar a
                utilização dos recursos arrecadados pela Arquidiocese em
                benefício dos atingidos.
              </p>
              <p>
                O acordo previu a criação de um órgão de divulgação dos
                atingidos e ainda a realização de uma reunião entre atingidos
                dos cinco municípios para apresentarem projetos a serem
                custeados com os recursos arrecadados pela Arquidiocese de
                Mariana já que, inicialmente, esta arrecadou recursos para todos
                atingidos que fazem parte da circunscrição da entidade, quer
                sejam Mariana, Barra Longa, Rio Doce, Sem Peixe ou Santa Cruz do
                Escalvado. Assim, após essa reunião, foi celebrado o Termo de
                Compromisso de Ajustamento de Conduta Definitivo, e o primeiro
                projeto aprovado foi a publicação do
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'projeto_sirene' }"
                  >Jornal A Sirene</router-link
                >.
              </p>
              <p>
                No dia 14 de setembro de 2016, após o Ministério Público ter
                postulado a Ação Civil Pública incidental nº0400.16.003473-4,
                indicando a necessidade de uma
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_assessoria_tecnica' }"
                  >assessoria técnica de confiança dos atingidos e atingidas de
                  Mariana</router-link
                >, a Cáritas MG foi a entidade escolhida pelas pessoas atingidas
                de Mariana.
              </p>
              <p>
                Desde então, a Assessoria Técnica Independente atua no
                território prestando informações técnicas nas mais diversas
                áreas contribuindo para o enfrentamento e o diálogo com as
                empresas, numa perspectiva de isonomia. Para isso, montou uma
                equipe multidisciplinar, marcada pelo envolvimento com causas
                sociais e/ ou lutas em favor das minorias. Assim, desvinculada
                das empresas e, portanto, não subordinada à Samarco, Vale, BHP
                e, muito menos, à Fundação Renova, a Assessoria Técnica tem a
                liberdade de trabalhar em conjunto com os atingidos em busca da
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                  >Reparação Integral</router-link
                >.
              </p>
            </template>

            <template v-slot:footerImage>
              <div class="card__footer_img mt-3">
                <img width="50%" src="/logos/logo_minas_gerais.png" alt="" />
              </div>
            </template>
          </Card>
        </v-col>

        <v-card class="mt-5 pa-3">
          <p class="term__text">
            <sup>1</sup> O Termo de Compromisso de Ajustamento de Conduta foi
            formalizado no dia 10 de janeiro de 2017 com a Arquidiocese de
            Mariana. Por meio do Termo de Compromisso de Ajustamento de Conduta
            o Ministério Público instaurou o Inquérito Civil nº 0400.16.000089-1
            para fiscalizar as contas da Arquidiocese.
          </p>
        </v-card>

        <v-row class="d-flex justify-center">
          <v-col sm="12" md="7" class="card__footer_btn">
            <div
              @click="navigateTo('sobre_pipam')"
              class="white--text pa-3 nav__button_intern"
            >
              Conhecer "PIPAM"
            </div>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import InsideCard from "../../components/InsideCard.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    InsideCard,
    GoBack,
  },

  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Quem somos /",
          href: "cards",
        },

        {
          text: " Cáritas Regional Minas Gerais",
          href: "sobre_caritas_regional",
        },
      ],
    };
  },

  methods: {
    navigateTo(route) {
      return this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}

.section__container_background {
  position: fixed;
  top: 0;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
  }
}

.position--relative {
  position: relative;
}
.section__container {
  height: 36%;
  width: 100%;
  position: relative;
}

.inside__card {
  position: relative;
  padding: 1em;
}

.inside__card img {
  width: 50px;
  height: 50px;
  margin-bottom: 1em;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.term__text {
  font-size: 0.5em;
  margin-bottom: 0;
}

.sobre_section_bc-color p:last-child {
  color: #c92f05;
}
</style>
