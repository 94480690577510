<template>
  <div
    class="section__about_inside-card d-flex flex-column justify-center align-center"
  >
    <slot name="img" />
    <p><strong> {{ subtitle }} </strong></p>
  </div>
</template>

<script>
export default {
  props: ["imgSrc", "subtitle"],
};
</script>

<style scoped>
.section__about_inside-card {
  border: 0.5px solid #c0c0c0;
  border-radius: 8px;

  height: 100%;
}

</style>
